.image-crop {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  border-color: black;
  border-width: 6px;
  width: 140px;
  height: 140px;
  position: absolute;
  top:50%;
  left:20%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border-radius: 50%;
  border-color: #043857;
  border-width: 3px;
  background-color: rgb(201, 201, 201);
  display: flex;
  opacity: 1
}
.image-crop:hover {
  width: 142px;
  height: 142px;
  transform: translate(-50%, -50%);
  border-color: black;
  border-width: 3px;
  opacity: 0.8;
  cursor: pointer
}
@media (max-width: 1023px) {
  /* .image-crop {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  border-color: black;
  border-width: 6px;
  width: 100px;
  height: 100px;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border-radius: 50%;
  border-color: #043857;
  border-width: 3px;
  background-color: rgb(201, 201, 201);
  display: flex;
  opacity: 1
}
.image-crop:hover {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  border-color: black;
  border-width: 6px;
  width: 102px;
  height: 102px;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border-radius: 50%;
  border-color: #043857;
  border-width: 3px;
  background-color: rgb(201, 201, 201);
  display: flex;
  opacity: 1
} */
}
.card-header {
    font-weight:400;
    font-size:3ch;
    line-height: 32px;
}
.card-header:hover {
  font-weight:900;
  font-size:3ch;
  cursor: pointer
}