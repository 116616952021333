.container {
  background-color: rgb(255, 255, 255);
  height: 100vh;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
