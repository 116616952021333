.image-crop {
  overflow: hidden;
  border-color: black;
  border-width: 6px;
  width: 200px;
  height: 200px;
  position: absolute;
  top:48%;
  left:80%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border-radius: 10%;
  border-color: #043857;
  border-width: 3px;
  background-color: rgb(201, 201, 201);
  display: flex;
  z-index: 1;
}
.image-crop-logo {
  overflow: hidden;
  width: 300px;
  height: 300px;
  margin: 3%;
  position: relative;
  right:10px;
  border-radius: 10%;
  border-color: #043857;
  border-width: 1px;
  background-image: linear-gradient(to top, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%);
  z-index: 1;
}
.image-crop:hover {
  width: 210px;
  height: 210px;
  top:48%;
  left:80%;
  transform: translate(-50%, -50%);
  border-color: black;
  border-width: 3px;
}
.image-crop-flow {
  overflow: hidden;
  border-color: black;
  border-width: 6px;
  width: 300px;
  margin: 3%;
  position: relative;
  right:10px;
  border-radius: 10%;
  border-color: #043857;
  border-width: 3px;
  background-color: rgb(201, 201, 201);
  z-index: 1;
}
.image-mobile {
  position: relative;
  overflow: hidden;
  border-radius: 10%;
  border-color: black;
  border-width: 6px;
  width: 200px;
  height: 200px;
  left: 50%;
  top: -50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border-color: #043857;
  border-width: 3px;
  background-color: rgb(201, 201, 201);
  display: flex;
  z-index: 1;
}
.image-spec {

  border-color: black;
  border-width: 0px;
  display: flex;
  z-index: -3;
  opacity: 1;
  overflow: hidden;
  border-radius: 5%;
  border-color: black;
  
}