
         .btn-grad {
            background-image: linear-gradient(to right, #1A2980 0%, #26D0CE  51%, #1A2980  100%);
            margin: 10px;
            padding: 10px 35px;
            text-align: center;
            text-transform: uppercase;
            transition: 0.5s;
            background-size: 200% auto;
            color: white;            
            box-shadow: 0 0 20px #eee;
            border-radius: 10px;
            display: block;

            font-family: 'Plus Jakarta Sans';
            font-weight: 800;
            font-size: 20px;
            letter-spacing: 2px;
            z-index: 100;
          }

          .btn-grad:hover {
            background-position: right center; /* change the direction of the change here */
            color: #0E7EBF;
            text-decoration: none;
          }                  
          .btn-grad-diy {
            background-image: linear-gradient(to right, #1A2980 0%, #26D0CE  51%, #1A2980  100%);
            margin: 10px;
            padding: 10px 30px;
            text-align: center;
            text-transform: uppercase;
            transition: 0.5s;
            background-size: 200% auto;
            color: white;            
            box-shadow: 0 0 20px #eee;
            border-radius: 10px;
            display: block;

            font-family: 'Plus Jakarta Sans';
            font-weight: 800;
            font-size: 16px;
            letter-spacing: 2px;
          }
        
                
         
          .btn-grad-wishlist {
            background-image: linear-gradient(to right, #16222A 0%, #3A6073  51%, #16222A  100%);
            margin: 10px;
            padding: 10px 30px;

            text-align: center;
            text-transform: uppercase;
            transition: 0.5s;
            background-size: 200% auto;
            color: white;            
            box-shadow: 0 0 20px #eee;
            border-radius: 10px;
            display: block;            
            font-weight: 800;
            font-size: 16px;
            letter-spacing: 2px;
          }

          .btn-grad-wishlist:hover {
            background-position: right center; /* change the direction of the change here */
            color: #fff;
            text-decoration: none;
          }
         
          .btn-grad-diy:hover {
            background-position: right center; /* change the direction of the change here */
            color: #0E7EBF;
            text-decoration: none;
          }                  
          
          .btn-grad-navi {
            background-image: linear-gradient(to right, #77A1D3 0%, #79CBCA  51%, #77A1D3  100%);
            margin: 10px;
            padding: 10px 30px;
            text-align: center;
            text-transform: uppercase;
            transition: 0.5s;
            background-size: 200% auto;
            color: white;            
            box-shadow: 0 0 20px #eee;
            border-radius: 10px;
            display: block;

            font-family: 'Plus Jakarta Sans';
            font-weight: 800;
            font-size: 16px;
            letter-spacing: 2px;
            position: relative;
            bottom: 16px;
            right:-50px
          }
          .btn-grad-navi-air {
            /* background-image: linear-gradient(to right, #77A1D3 0%, #79CBCA  51%, #77A1D3  100%); */
            /* box-shadow: 0 0 20px #eee; */
            border-color: #16222A;
            border-width: 3px;
            margin: 10px;
            padding: 8px 15px;
            text-align: center;
            text-transform: uppercase;
            /* transition: 0.5s; */
            background-size: 200% auto;
            color: rgb(0, 0, 0);            
            border-radius: 10px;
            display: block;

            font-family: 'Plus Jakarta Sans';
            font-weight: 800;
            font-size: 16px;
            letter-spacing: 2px;
            position: relative;
            bottom: 40px;
            right:-50px
          }
          .btn-grad-navi-air:hover {
            border-color: #0E7EBF;
            color:#0E7EBF
          }
          .title-grad-navi {
            letter-spacing: 2px;
            font-family: 'Plus Jakarta Sans';
  font-size: 24px;
  font-weight: 800;
  color: black;
  line-height: 150%;
  position: relative;
  top:6px;

  text-transform: uppercase;
          }
          .btn-grad-navi-container {
            font-family: 'Plus Jakarta Sans';
            font-weight: 800;
            position: relative;
            top:15px;
            right:0em
          }


          .btn-grad-navi:hover {
            background-position: right center; /* change the direction of the change here */
            color: #fff;
            text-decoration: none;
          }
          .card {
            background-color: black;
          }
          .card-special {
            background-image: linear-gradient(to top, #3b41c5 0%, #a981bb 49%, #ffc8a9 100%);
          }

          .card-special:hover {
            background-image: linear-gradient(to top, #3b41c5 0%, #a981bb 49%, #ffc8a9 100%);
          }

          .hoverPoint:hover {
            cursor:pointer
          }