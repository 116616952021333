@media screen and
  (prefers-reduced-motion: reduce), 
  (update: slow) {
  * {
    animation-duration: 0.001ms !important;
    animation-iteration-count: 1 !important; /* Hat tip Nick/cssremedy (https://css-tricks.com/revisiting-prefers-reduced-motion-the-reduced-motion-media-query/#comment-1700170) */
    transition-duration: 0.001ms !important;
  }
}
body {
  font-family: 'Plus Jakarta Sans';
  /* background-image: linear-gradient( 115.3deg,  rgb(18, 0, 44) 7.4%, rgba(255,255,255,1) 103.1% ); */
  background-repeat: no-repeat;
  background-attachment: relative;
  background-image: url("roof.png");
  background-position-x: 50%;
  background-position-y: 5100px;
  background-size: 1400px;
}
@media (min-width:300px) {
  body {
  background-position-y: 5100px;
  }
}
@media (min-width:360px) {
  body {
  background-position-y: 5000px;
  }
}
@media (min-width:380px) {
  body {
  background-position-y: 5100px;
  }
}
@media (min-width:400px) {
  body {
  background-position-y: 5200px;
  }
}
@media (min-width:768px) {
  body {
  background-position-y: 2900px;
  }
}
@media (min-width:1000px) {
  body {
  background-position-y: 2900px;
  }
}
@media (min-width:1200px) {
  body {
  background-position-y: 3100px;
  }
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
.background-roof {
  background-repeat: no-repeat;
  background-attachment: relative;
  background-image: url("roof.png");
  background-position-x: 50%;
  background-position-y: -200px;
  background-size: 1400px;
}
.header {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 70px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1);
  z-index: 10;
}
.selector {
  transition-property: all;
  transition-duration: 300ms;
  padding: 10px;
  border-radius: 10px;
  border-color: #000000;
  background-color: #f4f4f4d3;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1);
    width: fit-content;
    margin: auto;
    position: relative;
    z-index: 10;
}
.selector:hover {
  transition-property: all;
  transition-duration: 300ms;
  background-color: #0e7ebf4e;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1);
}
.screenshot {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  border-color:#a7a7a7;
  border-width: 1px;
  border-radius: 5px;
    background-color: rgb(255, 255, 255);
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1);
}
.popin-card {
  border-radius: 50px;
  width: inherit;
  height:inherit;
  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  position: relative;
  top:20px;
}
@media (min-width:991px) {
  .popin-card {
    height:auto
  }
}@media (min-width:768px) {
  .popin-card {
    width:100%
  }
}
.videoWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  text-align: center;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.screenshot:hover {
  cursor: pointer;
}
.fade-image {
opacity: 0.7;
border-radius: 50%;
width:80%;
}
/* .screenshot:hover .screenshot-image {
  filter: brightness(50%);
} */
.screenshot-image {
  filter: brightness(100%);
}
/* .screenshot-image:hover {
  filter: brightness(50%);
} */
.screenshot-middle {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  background-color: white;
  color: black;
  padding: 2%;
  /* border-radius: 10px; */
  width: 100%
  
}
.screenshot:hover .screenshot-middle {
  opacity: 0.9;
}
.image-right {
/* 61c560090de02f31d453668f-bedroom 1 */

width: auto;
height: auto;

filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
border-radius: 24px;
transform: matrix(-1, 0, 0, 1, 0, 0);

/* Inside auto layout */
flex: none;
order: 1;
flex-grow: 0;

background-color: #08AEEA;
background-image: linear-gradient(0deg, #08AEEA 0%, #2AF598 100%);
background-color: #D9AFD9;
background-image: linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%);
background-color: #FFDEE9;
background-image: linear-gradient(0deg, #FFDEE9 0%, #B5FFFC 100%);





}
.paragraph-header-center {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 150%;
  font-family: 'Plus Jakarta Sans';
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  }
  .paragraph-header {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 35px;
    font-family: 'Plus Jakarta Sans';
    }
    .para-container {
      position: relative;
      top:150px;
      right:0px;
      left:0px
    }
.paragraph-subheader {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  }
.paragraph-header-blue {
  font-family: 'Plus Jakarta Sans';
  font-size: 36px;
  font-weight: 600;
  color: #0E7EBF;
  line-height: 150%;
  background: -webkit-linear-gradient(0deg, #08AEEA 0%, #2AF598 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
  .paragraph-subheader-blue {
    font-family: 'Plus Jakarta Sans';
    font-size: 36px;
    font-weight: 600;
    color: #0E7EBF;
    line-height: 150%;
    background: -webkit-linear-gradient(0deg, #08AEEA 0%, #2AF598 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width:300px
    }
    @media screen and (max-width: 768px) {
      .paragraph-subheader-blue {
        width:auto
      }
    }

  .center-text {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 45px;
    text-align: center;
    letter-spacing: 0.1em;
    vertical-align: middle;
    
    /* Primary/Blue */
    color: #000000;
    justify-content: center;
    padding: 0px;
    gap: 20px;
    width: 100%;
    
    font-family: 'Plus Jakarta Sans';
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    }
    .center-text-card {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 45px;
      text-align: center;
      letter-spacing: 0.1em;
      vertical-align: middle;
      
      /* Primary/Blue */
      color: #000000;
      justify-content: center;
      padding: 0px;
      gap: 20px;
      width: 100%;
      
      font-family: 'Plus Jakarta Sans';
      position: relative;
      left: 50%;
      transform: translate(-50%, 20%);
    }
    .footer-text {

      transform: translate(0%, 25%);
      }
      .grid-container {
          align-items: center;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
      }
    .center-header {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 25px;
      text-align: center;
      letter-spacing: 0.1em;
      /* background-color: black; */
      /* Primary/Blue */
      color: #000000;
      justify-content: center;
      padding: 0px;
      gap: 20px;
      width: 100%;

      font-family: 'Plus Jakarta Sans';
      display: flex; /* establish flex container */
      justify-content: center; /* center flex items horizontally, in this case */
      align-items:center /* center flex items vertically, in this case */
      }
      .light-weight {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0.1em;
        /* background-color: black; */
        /* Primary/Blue */
        color: #000000;
        justify-content: center;
        padding: 0px;
        gap: 20px;
        width: 100%;
  
        font-family: 'Plus Jakarta Sans';
        display: flex; /* establish flex container */
        justify-content: center; /* center flex items horizontally, in this case */
        align-items:center /* center flex items vertically, in this case */
      }
      .bullet {
        padding: 50px;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 45px;
        text-align: left;
        letter-spacing: 0.1em;
        font-family: 'Plus Jakarta Sans'
      }
      /* .bullet-head {
        padding: 50px;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 40px;
        text-align: left;
        letter-spacing: 0.1em;
        font-family: 'Plus Jakarta Sans'
      } */
      .bullet-head {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: left;
        letter-spacing: 0.1em;
        font-family: 'Plus Jakarta Sans';
        position:'relative'
      }
      .bullet-head-bold {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: left;
        letter-spacing: 0.1em;
        font-family: 'Plus Jakarta Sans';
        position:'relative'
      }
      .bullet-paragraph {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        letter-spacing: 0.1em;
        font-family: 'Plus Jakarta Sans'
      }
      .special-paragraph {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        letter-spacing: 0.1em;
        font-family: 'Plus Jakarta Sans';
        background: -webkit-linear-gradient(0deg, #08AEEA 0%, #2AF598 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      
      }
.header-container {
  composes: container;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  display: flex;
  justify-content: space-between;
}
.review-box {
  color: #B5FFFC;
padding: 15px;
border-radius: 20px;
  width: 200px;
  box-shadow: 2px 2px #b8b8b8;
  background-color: #21D4FD;
  background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);
  background-color: #F4D03F;
background-image: linear-gradient(132deg, #F4D03F 0%, #16A085 100%);
background-color: #FF3CAC;
background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);
text-align:right;
position: relative;
top: 50%;
left: 30%;
transform: translate(-50%, -50%);
}
.logo-wrapper {
  position: relative;
  top: 0px;
  width: 110px;
}

.menu {
  position: relative;
  z-index: 50;
}

.menu-button {
  display: flex;
  align-items: center;
}
.menu-button > svg {
  margin-right: 1px;
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  color: rgb(107, 114, 128);
}
.menu-button:hover > svg {
  color: currentColor;
}

.menu-transition-enter {
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 150ms;
}
.menu-transition-enter-from {
  opacity: 0;
  transform: scale(0.95, 0.95);
}
.menu-transition-enter-to {
  opacity: 1;
  transform: scale(1, 1);
}
.menu-transition-leave {
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 150ms;
}
.menu-transition-leave-from {
  opacity: 1;
  transform: scale(1, 1);
}
.menu-transition-leave-to {
  opacity: 0;
  transform: scale(0.95, 0.95);
}

.menu-items-container {
  position: absolute;
  right: 0px;
  width: 18rem;
  overflow: hidden;
  margin-top: 0.25rem;
  transform-origin: top right;
  background-color: rgb(255, 255, 255);
  border-radius: 0.375rem;

  box-shadow: 0 0 0 0px #fff, 0 0 0 calc(1px) rgb(0 0 0 / 0.05), 0 0 #0000,
    0 0 #0000, 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.menu-items-container:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.menu-items-container > * + * {
  border-top-width: 1px;
  border-color: rgb(243, 244, 246);
}

.menu-header {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0.5rem;
}
.menu-header > * + * {
  margin-left: 0.5rem;
}

.user-details {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-email {
  color: rgb(107, 114, 128);
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.menu-items {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.menu-item {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.menu-item:last-child {
  border-top-width: 1px;
  padding-top: 0.5rem;
  margin-top: 0.5rem;
}
.menu-item > a,
.menu-item > button {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.375rem;
}
.menu-item > a:hover,
.menu-item > button:hover {
  background-color: rgb(243, 244, 246);
}
.menu-item > a > svg,
.menu-item > button > svg {
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  color: rgb(107, 114, 128);
}

.main {
  margin-top: 60px;
}

.footer {
    width: 100%;
    position: relative;
    left: 0;
    bottom: 0;
    background-color: #FFDEE9;
    color: black;
    text-align: center;
      bottom: 0px;
      left: 0px;
      right: 0px;
      height: 60px;
      background-color: rgb(255, 255, 255);
      z-index: 10;
}
.review-card {
  width:10px;
  height: 200px;
  border-color: black;
  border-style: solid;
}
.btn-grad-card {

  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);

}