* {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: rgb(243, 244, 246);
}

html,
body {
  
  padding: 0;
  margin: 0;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: inherit;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-size: 1em;
}

button,
input {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button {
  -webkit-appearance: button;
  text-transform: none;
  cursor: pointer;
  background-color: transparent;
  background-image: none;
}

